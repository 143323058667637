import { Outlet } from "react-router-dom";
import HMNavbar from '../components/Navbar';

const Layout = () => {
  return (
    <>
        <HMNavbar />
        <Outlet />
    </>
    
  )
};

export default Layout;