import React, { useState, useEffect } from "react";
import Tabs from "./Tabs";
import Schedule from "./Schedule";

const Home = () => {
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState("Friday");

  // Function to fetch events
  const fetchEvents = () => {
    fetch("https://panels.holidaymatsuri.com/api/v3/events")
      .then((response) => response.json())
      .then((data) => {
        // Filter out cancelled events
        const filteredEvents = data.filter((event) => event.status !== "cancelled");
        setEvents(filteredEvents);
      });
  };

  // Fetch events initially and set up an interval to re-fetch
  useEffect(() => {
    fetchEvents(); // Initial fetch
    const interval = setInterval(fetchEvents, 60000); // Fetch every 60 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Group events by day
  const days = ["Friday", "Saturday", "Sunday"];
  const eventsByDay = days.reduce((acc, day) => {
    acc[day] = events.filter((event) => event.day === day);
    return acc;
  }, {});

  return (
    <div>
      <Tabs days={days} selectedDay={selectedDay} onSelectDay={setSelectedDay} />
      <Schedule events={eventsByDay[selectedDay]} />
    </div>
  );
};

export default Home;
