import React from "react";

const Tabs = ({ days, selectedDay, onSelectDay }) => {
  return (
    <div className="tabs-container">
      {days.map((day) => (
        <div
          key={day}
          className={`tab ${selectedDay === day ? "active-tab" : ""}`}
          onClick={() => onSelectDay(day)}
        >
          {day}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
